<template>
  <div class="home-page">
    <hero-section
        :info="hero.info"
        :title="hero.title"
        :description="hero.description"
    />

    <tools-section
        :content="toolsTitle.content"
        :title="toolsTitle.title"
        :description="toolsTitle.description"
    >
      <template v-slot:tool-boxes-container>
        <tool-box
            v-for="(toolBox, key) in toolBoxes"
            :label="toolBox.label"
            :description="toolBox.description"
            :icon="toolBox.icon"
        />
      </template>
    </tools-section>

    <apps-section
        :title="appTitle.title"
        :content="appTitle.content"
    />

    <iterate-and-publish-section/>

    <video-section
        :title="video.title"
        :description="video.description"
        :color="video.color"
    />

    <tools-section
        content="COLLABORATE WITH EASE"
        title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
        description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. "
    >
      <template v-slot:tool-boxes-container>
        <tool-box
            v-for="(toolBox, key) in secondToolBoxes"
            :label="toolBox.label"
            :description="toolBox.description"
            :icon="toolBox.icon"
        />
      </template>
    </tools-section>

    <carousel-section/>

    <call-to-action-section
        :title="callToAction.title"
        :description="callToAction.description"
    />

  </div>
</template>

<script>
import HeroSection from "@/components/HeroSection";
import ToolsSection from "@/components/ToolsSection";
import AppsSection from "@/components/AppsSection";
import IterateAndPublishSection from "@/components/IterateAndPublishSection";
import VideoSection from "@/components/VideoSection";
import CallToActionSection from "@/components/CallToActionSection";
import ToolBox from "@/components/ToolBox";
import CarouselSection from "@/components/CarouselSection";

import {hero} from "@/services/home/hero";
import {toolsTitle, toolBoxes, secondToolBoxes} from "@/services/home/tools";
import {appTitle} from "@/services/home/apps";
import {video} from "@/services/home/video";
import {callToAction} from "@/services/home/callToAction";

export default {
  name: "HomePage",
  components: {
    CarouselSection,
    CallToActionSection,
    VideoSection,
    HeroSection,
    ToolsSection,
    AppsSection,
    IterateAndPublishSection,
    ToolBox
  },
  data: () => ({hero, toolsTitle, toolBoxes, secondToolBoxes, appTitle, video, callToAction}),
}

</script>

<style lang="scss">
@import "HomePage";
</style>
