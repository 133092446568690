<template>
  <div class="carousel-section">
    <div class="main-container">

      <carousel :items-to-show="1" :wrap-around="true">
        <slide v-for="(testimonial, key) in testimonials" :key="key">
          <div class="img-container">
            <img class="icon-quotes" alt="profile image" src="../assets/icons/icon-quotes.png">
            <img class="img" alt="profile image" :src="imgUrl(testimonial.profileImg)">
          </div>
          <div class="content-container">
            <div class="info">
              {{ testimonial.info }}
            </div>
            <div class="name">{{ testimonial.name }}</div>
            <div class="job">{{ testimonial.job }}</div>
          </div>
        </slide>

        <template #addons>
          <navigation/>
          <pagination/>
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';
import {testimonials} from "@/services/home/testimonials";


export default {
  name: "CarouselSection",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data: () => ({testimonials}),

  methods: {
    imgUrl(img) {
      return require(`../../src/assets/${img}.jpg`)
    },
  }

}

</script>

<style lang="scss">
@import "CarouselSection";
</style>
