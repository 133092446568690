<template>
  <div class="apps-section">
    <div class="main-container">
      <usdzen-title
          :title="title"
          :content="content"
      />

      <div class="apps-boxes-container">
        <app-box/>
      </div>

    </div>
  </div>
</template>

<script>
import UsdzenTitle from "@/components/ui/UsdzenTitle";
import AppBox from "@/components/AppBox";

export default {
  name: "AppsSection",
  components: {
    UsdzenTitle,
    AppBox
  },
  props: {
    title: {
      type: String
    },
    content: {
      type: String
    },
  },

}

</script>

<style lang="scss">
@import "AppsSection";
</style>
