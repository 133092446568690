<template>
  <nav class="nav">
    <div class="logo-container">
      <router-link to="/" class="logo">
        <img src="@/assets/logo.png" alt="Logo">
      </router-link>
    </div>
    <div class="nav-container">
      <router-link to="/pricing">Pricing</router-link>
      <a class="login" href="https://app.usdzen.com/#/projects" target="_blank">Login</a>
    </div>
  </nav>

  <router-view/>

  <footer class="nav footer">
    <div class="logo-container">
      <router-link to="/" class="logo">
        <img src="@/assets/logo.png" alt="Logo">
      </router-link>
      <router-link to="/privacy-policy">Privacy Policy</router-link>
      <span>|</span>
      <router-link to="/pricing">Pricing</router-link>
    </div>
    <div class="nav-container">
      <span>Usdzen @2022 All rights reserved.</span>
    </div>
  </footer>
</template>


<style lang="scss">
@import "src/css/variables";
@import "src/css/breakpoints";
@import "src/css/overwrites";

@font-face {
  font-family: 'Milliard-Book';
  src: url('css/fonts/MilliardBook.otf');
}

@font-face {
  font-family: 'Milliard-Bold';
  src: url('css/fonts/MilliardBold.otf');
}

#app {
  font-family: Milliard-Book;
}

.nav {
  display: flex;
  padding: 30px 10%;
  background: $bgDark;
  justify-content: space-between;
  align-content: center;
  color: $clTextLightGray;

  .logo-container {
    width: 140px;
    height: 50px;

    .logo {

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  a {
    font-weight: bold;
    color: $clTextLightGray;
    transition: 0.2s;
    margin: 0px 10px;

    &:hover {
      color: $primaryDarken;
    }

    &.router-link-exact-active {
      color: $primaryDarken;
    }

    &.login {
      padding: 10px 45px;
      background: $primary;
      color: $clText;

      &:hover {
        background: $primaryDarken;
        color: $clTextLightGray;
        transition: 0.2s;
      }
    }
  }

  .nav-container {
    align-self: center;
  }

  &.footer {

    .logo-container {
      display: flex;
      align-items: center;
      width: auto;
      height: auto;

      .logo {

        img {
          width: 140px;
          height: 50px;
        }
      }
    }
  }

  @media only screen and (max-width: $desktop) {
    padding: 20px 0%;

    &.footer {
      flex-direction: column;
      text-align: center;

      .logo-container {
        display: flex;
        flex-direction: column;

        a {
          margin: 3px 0px;
        }

        .logo {
          margin-bottom: 20px;
        }

        span {
          display: none;
        }
      }

      .nav-container {
        margin-top: 15px;
      }
    }
  }

  @media only screen and (max-width: $mobile) {
    a {
      margin-left: 0px;

      &.login {
        padding: 5px 20px;
        margin-left: 10px;
      }
    }
  }
}
</style>

<style lang="scss">
@import "src/css/app";
</style>

<script>
import UsdzenButton from "@/components/ui/UsdzenButton";

export default {
  components: {UsdzenButton}
}
</script>