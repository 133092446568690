const appTitle = {
    title: `This is how the app looks like in action`,
    content: `A REAL SHOWCASE`,
}

const
apps = [
    {
        title: `Lorem ipsum dolor sit amet, consetetur sadipsci`,
        description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.`,
        img: `app-img`,
    },
    {
        title: `Lorem ipsum dolor sit amet, consetetur sadipsci`,
        description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.`,
        img: `app-img-2`,
    },
]

export {
    appTitle,
    apps
}