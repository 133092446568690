<template>
  <div class="app-box" v-for="(app, key) in apps" :key="key">
    <div class="img-container">
      <img alt="logo" :src="imgUrl(app.img)">
    </div>

    <div class="content-container">
      <div class="app-title">
        {{ app.title }}
      </div>
      <div class="app-description">
        {{ app.description }}
      </div>
    </div>
  </div>
</template>

<script>
import {apps} from "@/services/home/apps";

export default {
  name: "AppBox",
  data: () => ({apps}),
  methods: {

    imgUrl(img) {
      return require(`../../src/assets/${img}.jpg`)
    },
  }
}


</script>

<style lang="scss">
@import "AppBox";
</style>
