<template>
  <div class="usdzen-title" :class="{[color]: true}">
      <div class="content"> {{ content }}</div>
      <h1 class="title">{{ title }}</h1>
      <div class="description">{{ description }}</div>
  </div>
</template>

<script>

export default {
  name: "UsdzenTitle",
  props: {
    content: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
}

</script>

<style lang="scss">
@import "UsdzenTitle";
</style>
