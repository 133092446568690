<template>
  <div class="hero-section">
    <div class="main-container">
      <div class="info-container">
        <p class="info">{{ info }}</p>
        <h1 class="title">{{ title }}</h1>
        <p class="info-text">{{ description }}</p>
        <usdzen-button theme="inverted"/>
      </div>
      <div class="img-container">
        <img :src="imgUrl(img)" class="img" alt="logo">
      </div>
    </div>
  </div>
</template>

<script>

import UsdzenButton from "@/components/ui/UsdzenButton";
import UsdzenTitle from "@/components/ui/UsdzenTitle";
export default {
  name: "HeroSection",
  components: {UsdzenTitle, UsdzenButton},
  props: {
    info: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    img: {
      type: String,
      default: 'USDZen-main-img'
    }
  },

  methods: {
    imgUrl(img) {
      return require(`../../src/assets/${img}.png`)
    },
  }
}



</script>

<style lang="scss">
@import "HeroSection";
</style>
