<template>
  <div class="tool-box">
    <img :src="imgUrl(icon)" alt="icon">
    <div class="box-title" :class="{[color]: true}">
      {{label}}
    </div>
    <div class="box-description">
      {{description}}
    </div>
  </div>
</template>

<script>

export default {
  name: "ToolBox",
  props: {
    label: {
      type: String
    },
    description: {
      type: String
    },
    color: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: 'icon-archive'
    }
  },

  methods: {
    imgUrl(icon) {
      return require(`../../src/assets/icons/${icon}.svg`)
    },
  }
}


</script>

<style lang="scss">
@import "ToolBox";
</style>
