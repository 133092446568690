const iterateTitle = {
    color: "inverted",
    title: "Iterate and publish with confidence",
    content: "ITERATE FASTER",
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
}

const iterateBoxes = [
    {
        label: `MAKE CHANGES IS A MATTER OF MINUTES`,
        description: `See the changes almost instantly without creating new apps or updating existing with the new assets.`,
        iconImg: `icon-check-hand`,
        class: 'dark',
    },
    {
        label: `VERSION YOUR ASSETS`,
        description: `Easily upload new versions, see how they look and iterate until perfect.`,
        iconImg: `icon-versioning`,
        class: 'dark',
    },
    {
        label: `SHARE WITH DECISION MAKERS`,
        description: `Provide valuable feedback and improve.`,
        iconImg: `icon-people-conversation`,
        class: 'dark',
    },
]

export {
    iterateTitle,
    iterateBoxes
}