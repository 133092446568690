<template>
  <div class="iterate-and-publish-section">
    <div class="main-container">
      <usdzen-title
          :color="iterateTitle.color"
          :title="iterateTitle.title"
          :content="iterateTitle.content"
          :description="iterateTitle.description"
      />

      <div class="tool-boxes-container">
        <tool-box
            v-for="(iterateBox, key) in iterateBoxes" :key="key"
            :class="iterateBox.class"
            :icon="iterateBox.iconImg"
            :label="iterateBox.label"
            :description="iterateBox.description"
        />
      </div>

    </div>
  </div>
</template>

<script>
import UsdzenTitle from "@/components/ui/UsdzenTitle";
import ToolBox from "@/components/ToolBox";

import {iterateTitle, iterateBoxes} from "@/services/home/iterate";


export default {
  name: "IterateAndPublishSection",
  components: {
    UsdzenTitle,
    ToolBox,
  },
  data: () => ({iterateTitle, iterateBoxes}),
}

</script>

<style lang="scss">
@import "IterateAndPublishSection";
</style>
