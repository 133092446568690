const testimonials = [
    {
        info: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna`,
        name: `Maya Mileska`,
        job: `3D Modeler`,
        profileImg: `person`,
    },
    {
        info: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna`,
        name: `Done Donevski`,
        job: `UX Design`,
        profileImg: `person`,
    },
    {
        info: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna`,
        name: `Petar Petkovski`,
        job: `UI Design & Developer`,
        profileImg: `person`,
    },
]

export {
    testimonials
}