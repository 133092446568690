<template>
  <button :type="type" class="usdzen-button" :class="{[theme]: true}">
    {{label}}
  </button>
</template>

<script>
export default {
  name: 'UsdzenButton',
  props: {
    label: {
      type: String,
      default: 'Get the app'
    },
    theme: {
      type: String,
      default: 'primary'
    },
    type: {
      type: String,
      default: 'button'
    }
  }
}
</script>

<style lang="scss">
@import "UsdzenButton";
</style>
