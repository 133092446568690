import {createStore} from "vuex";

export default createStore({
    state: {
        packets: []
    },
    actions: {
        addPacket({commit}, packet) {
            commit('addPacket', packet)
        },
    },

    mutations: {
        addPacket(state, packet) {
            state.packets = [packet];
        },
    },
});