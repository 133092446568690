const toolsTitle = {
    content: `COLLABORATE WITH EASE`,
    title: `Additional tool in your skill arsenal`,
    description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.`,
}

const toolBoxes = [
    {
        label: `PROTOTYPE`,
        description: `Everyone works with the right assets`,
        icon: `icon-prototype`
    },
    {
        label: `REPEAT`,
        description: `Provide valuable feedback and improve`,
        icon: `icon-repeat`
    },
    {
        label: `TEST`,
        description: `View the assets in AR in Safari and native iOS`,
        icon: `icon-mobile`
    },

]

const secondToolBoxes = [
    {
        label: `ORGANIZE`,
        description: `All your assets are organized neatly in projects`,
        icon: `icon-organize`
    },
    {
        label: `ARCHIVE`,
        description: `Everythign is available for future use in one place`,
        icon: `icon-archive`
    },
    {
        label: `MAINTAIN`,
        description: `Easily upgrade your assets and share new versions acoress the team`,
        icon: `icon-mobile-repair`
    },

]

export {
    toolsTitle,
    toolBoxes,
    secondToolBoxes,
}