import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../pages/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/pricing',
    name: 'PricingPage',
    component: () => import(/* webpackChunkName: "about" */ '../pages/PricingPage.vue')
  },
  {
    path: '/checkout',
    name: 'CheckoutPage',
    component: () => import(/* webpackChunkName: "about" */ '../pages/CheckoutPage.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: () => import(/* webpackChunkName: "about" */ '../pages/PrivacyPolicyPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
