<template>
  <div class="tool-box-section">
    <div class="main-container">
      <usdzen-title
          :title="title"
          :content="content"
          :description="description"
          :color="color"
      />

      <div class="tool-boxes-container">
        <slot name="tool-boxes-container"></slot>
      </div>

    </div>
  </div>
</template>

<script>
import ToolBox from "@/components/ToolBox";
import UsdzenTitle from "@/components/ui/UsdzenTitle";

export default {
  name: "ToolsSection",
  components: {
    ToolBox,
    UsdzenTitle
  },
  props: {
    content: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    color: {
      type: String,
      default: 'primary'
    },
  },


}

</script>

<style lang="scss">
@import "ToolsSection";
</style>
