<template>
  <div class="call-to-action-section">
    <div class="main-container">
      <usdzen-title
          :title="title"
          :description="description"
      />

      <usdzen-button
          :label="label"
          :theme="theme"
      />
    </div>
  </div>
</template>

<script>
import UsdzenTitle from "@/components/ui/UsdzenTitle";
import UsdzenButton from "@/components/ui/UsdzenButton";

export default {
  name: "CallToActionSection",
  components: {
    UsdzenTitle,
    UsdzenButton,
  },
  props: {
    info: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    label: {
      type: String
    },
    theme: {
      type: String
    }
  },

}

</script>

<style lang="scss">
@import "CallToActionSection";
</style>
