<template>
  <div class="video-section">
    <div class="main-container">

      <div class="video-container">
        <!--        <video muted="" loop autoplay="" infinitive="" playsinline="">-->
        <!--          <source src="../assets/videos/Usdzen-video.mp4" type="video/mp4">-->
        <!--          Your browser does not support the video tag.-->
        <!--        </video>-->
        <usdzen-title
            :color="color"
            :title="title"
            :description="description"
        />

        <div class="play-btn">
          <span></span>
        </div>

        <div class="video-description">WATCH 2 MINUTES VIDEO</div>
      </div>


    </div>
  </div>
</template>

<script>
import UsdzenTitle from "@/components/ui/UsdzenTitle";

export default {
  name: "VideoSection",
  components: {
    UsdzenTitle,
  },
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    color: {
      type: String
    },
  },

}

</script>

<style lang="scss">
@import "VideoSection";
</style>
